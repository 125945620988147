import { HttpParams } from '@angular/common/http';

export class BaseService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public encodeObjectToQueryParams(obj: any): HttpParams {
    let params = new HttpParams();
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        if (Array.isArray(value)) {
          value.forEach(item => {
            params = params.append(`${key}`, item);
          });
        } else {
          if (value !== undefined && value !== null) {
            if (value instanceof Date) {
              params = params.set(key, value.toISOString());
            } else {
              params = params.set(key, value);
            }
          }
        }
      }
    }
    return params;
  }
}
